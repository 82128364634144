import React from "react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import GoogleLogo from "../assets/img/google.svg";
import AppleLogo from "../assets/img/apple.svg";

const Countries = ({ link }) => {
  return (
    <section
      className="blok bg-[#f7f7f7] md:max-w-7xl md:mx-auto my-8 rounded"
      style={{
        padding: 64,
      }}
    >
      <h2 className="text-4xl font-bold text-gray-900 text-center">
        Navio est chez vous
      </h2>

      <dl className="divide-y divide-gray-200">
        <ul className="my-8 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 justify-items-center">
          <li key={3} className="overflow-hidden rounded-xl shadow">
            <div className="flex items-center gap-x-4 bg-white p-6">
              <span className="fi fi-cm fis"></span>
              Cameroon
            </div>
          </li>
          <li key={2} className="overflow-hidden rounded-xl shadow">
            <div className="flex items-center gap-x-4 bg-white p-6 ">
              <span className="fi fi-fr fis"></span>
              France
            </div>
          </li>
          <li key={1} className="overflow-hidden rounded-xl shadow">
            <div className="flex items-center gap-x-4 bg-white p-6">
              <span className="fi fi-be"></span>
              Belgique
            </div>
          </li>
        </ul>
        <div className="md:px-4 py-6 flex md:flex-row  gap-x-2">
          <a href={"https://play.google.com/store/apps/details?id=com.navio.rxautoapp"} target="_blank" className="">
            <img className="h-16 w-auto" src={GoogleLogo} alt="Google logo" />
          </a>
          <a href={"https://apps.apple.com/us/app/navio-cm-mobilit%C3%A9-facile/id6476240482"} target="_blank" className="">
            <img className="h-16 w-auto" src={AppleLogo} alt="Apple logo" />
          </a>
        </div>
      </dl>
    </section>
  );
};

export default Countries;
