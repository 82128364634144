import React, { useState } from 'react'

export default function How() {

  return (
    <section
      className="blok max-w-3xl mx-auto bg-white flex justify-center relative p-8"
      style={{
      }}
    >
      <div className="sm:py-48 lg:py-16 items-center justify-center">
        <h2 className="text-3xl font-bold text-gray-900">Mission de Navio</h2>
        <p className="mt-4 text-gray-600">
          Chez Navio, nous révolutionnons la mobilité pour rendre chaque trajet quotidien
          accessible, fiable et écologique. Nous nous engageons à offrir un service de transport
          exceptionnel, confortable et sûr, sans stress. Notre application connecte les passagers
          avec des chauffeurs professionnels, soigneusement sélectionnés, et nos véhicules
          respectent des normes strictes de qualité et de sécurité. Notre service évolue constamment
          pour répondre aux besoins des clients, tout en contribuant positivement à
          l&apos;environnement et à la société. <br /> <br />
          Notre objectif est de garantir une expérience client exceptionnelle, soutenir nos
          chauffeurs et promouvoir des pratiques de transport durable.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">Nos valeurs :</h3>
        <ul className="mt-4 list-disc list-inside text-gray-600">
          <li>
            <strong>Sécurité et confort :</strong> Nos chauffeurs professionnels sont soigneusement
            sélectionnés et formés pour garantir votre sécurité et votre confort.
          </li>
          <li>
            <strong>Transparence :</strong> Nous offrons des tarifs clairs et sans frais cachés,
            avec des reçus détaillés pour chaque trajet.
          </li>
          <li>
            <strong>Innovation :</strong> Nous utilisons les dernières technologies pour améliorer
            continuellement notre service et offrir une expérience utilisateur optimale.
          </li>
        </ul>
      </div>
    </section>
  )
}
