import React, { useState } from "react";
import { couleur, navioCouleur } from "../color";

export default function Qrcode({ link }) {
  return (
    <section className={`px-8  py-16 bg-white items-center justify-center`}>
      <h2 className="text-5xl font-bold text-gray-800 text-center">
      Scannez le code QR pour télécharger l'application.
      </h2>
      <div className={`blok flex items-center justify-center p-8 relative`}>
        <div className="flex lg:flex-row flex-col py-12 sm:flex-col lg:gap-x-16 gap-y-4">
          <div>
            {" "}
            <img
              className="md:h-96 h-auto w-auto rounded shadow-lg"
              src={require("../assets/img/codeandroid.png")}
              alt=""
            />
            <p className="text-gray-800 text-xl mt-4">Google Play Store</p>
          </div>
          <div>
            <img
              className="md:h-96 h-auto w-auto rounded shadow-lg"
              src={require("../assets/img/codeapple.png")}
              alt=""
            />
            <p className="text-gray-800 text-xl mt-4">Apple Store</p>
          </div>
        </div>
      </div>
    </section>
  );
}
