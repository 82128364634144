import React, { useLayoutEffect, useRef, useState } from "react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import emailjs from "@emailjs/browser";
import Spinner from "../Spinner";
import Swal from "sweetalert2";

export default function Contact() {
  const form = useRef();
  const [loading, setloading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setloading(true);
    emailjs
      .sendForm("service_jqjhw5q", "template_vpn36qp", form.current, {
        publicKey: "fSlEejTYvFmF0wzR5",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          setloading(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Votre demande a bien été envoyée et est maintenant en cours de traitement. Nous reviendrons vers vous dès que possible.",
            showConfirmButton: false,
            timer: 1500
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
          form.current.reset();
          setloading(false);
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Votre demande n'a pas su etre envoyé",
            showConfirmButton: false,
            timer: 1500
          });
        }
      );
  };
  return (
    <>
    {loading && <Spinner/>}
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">
        <div className="px-4 sm:px-0">
          <h2 className="text-3xl font-semibold leading-7 text-gray-900">
            Avez vous des questions?
          </h2>
          <p className="mt-1 text-sm my-8 leading-6 text-gray-600">
          Merci de bien vouloir remplir ce formulaire et nous le faire parvenir. </p>
        </div>

        <form
          ref={form}
          onSubmit={sendEmail}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 w-max-lg sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 flex gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Prénom
                </label>
                <div className="mt-2">
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    autoComplete="given-name"
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nom
                </label>
                <div className="mt-2">
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    autoComplete="family-name"
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Adresse mail
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Pays
                </label>
                <div className="mt-2">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Cameroen</option>
                    <option>France</option>
                    <option>Belgique</option>
                  </select>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Votre demande
                </label>
                <div className="mt-2">
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    rows={6}
                    autoComplete="message"
                    className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <input
              type="submit"
              className="px-2 cursor-pointer rounded-md bg-green-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              value="Envoyer"
            />
          </div>
        </form>
      </div>
    </div>
    </>
  );
}
