import React, { useLayoutEffect, useState } from 'react'
import { FaEnvelope, FaFacebookSquare,FaInstagram, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { conditions, languages } from '../function';


const Footer = ({link}) => {
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);
  const language = languages.find(lang => lang.code === (navigator?.language || 'en'));
  const privacy = conditions.find(condition => ((((navigator?.language)&&(navigator?.language || 'fr').indexOf(condition?.lang) > -1) || condition?.lang == 'fr') && (condition?.country != 'Cameroun') && condition.type === 'Privacy'));
  const legal = conditions.find(condition => ((((navigator?.language)&&(navigator?.language || 'fr').indexOf(condition?.lang) > -1) || condition?.lang == 'fr') && (condition?.country != 'Cameroun') && condition.type === 'Legale'));
  const cookies = conditions.find(condition => ((((navigator?.language)&&(navigator?.language || 'fr').indexOf(condition?.lang) > -1) || condition?.lang == 'fr') && (condition?.country != 'Cameroun') && condition.type === 'Cookies'));

  console.log(navigator)

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setError(null);

          // Get the address from latitude and longitude
          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
            const data = await response.json();
            console.log(data)
            setAddress(`${data.address.town}, ${data.address.county}`);
          } catch (err) {
            setError("Impossible de retrouver l'erreur");
          }
        },
        (err) => {
          setError(err.message);
          setLocation(null);
          setAddress(null);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }}

    useLayoutEffect(() => {
      getLocation();
  
    }, [])

  return (
    <footer className="bg-[#272727] text-white py-10">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
            <div className="flex flex-col space-y-2">
            <h3 className="text-lg font-semibold mb-4">Navio</h3>
              <ul className="space-y-2">
              <li>
                <a
                  href="#"
                  className="text-sm hover:text-gray-300"
                >
                  Condition général
                </a>
              </li>
              <li>
                <a
                  href={cookies?.url}
                  className="text-sm hover:text-gray-300"
                >
                  Politique de cookies
                </a>
              </li>
              <li>
                <a
                  href={legal?.url}
                  className="text-sm hover:text-gray-300"
                >
                  Mentions légales
                </a>
              </li>
              <li>
                <a
                  href={privacy?.url}
                  className="text-sm hover:text-gray-300"
                >
                  Politique de confidentialité
                </a>
              </li>
            </ul>
            </div>
          </div>

          <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Réseaux sociaux</h3>
            <ul className="space-y-2">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100093313562135" className="text-sm hover:text-gray-300">
                <span className='flex flex-row items-center gap-x-2'><FaFacebookSquare />Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/naviocm?igsh=aWNocXN0MTF0cWw3&utm_source=qr"
                  className="text-sm hover:text-gray-300"
                >
                <span className='flex flex-row items-center gap-x-2'><FaInstagram />Instagram</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Nos Produits</h3>
            <ul className="space-y-2">
              <li>
                <a href={link} className="text-sm hover:text-gray-300">
                  Navio CM
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-1/4 mb-6 lg:mb-0">
            <h3 className="text-lg font-semibold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="mailto:contact@navio.cm"
                  className="text-sm hover:text-gray-300"
                >
                <span className='flex flex-row items-center gap-x-2'><FaEnvelope />contact@navio.cm</span>
                </a>
              </li>
              <li>
                <a
                  href="tel:+237690601936"
                  className="text-sm hover:text-gray-300"
                >
                <span className='flex flex-row items-center gap-x-2'><FaPhoneAlt />+237690601936</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-sm hover:text-gray-300"
                >
                <div className='flex flex-row items-start gap-x-2'><FaMapMarkerAlt /><div>
                  <span>
                  Bonamoussadi commissariat 12ème quartier Bonanga <br/> Douala  <br/> Cameroun
                  </span>
                  </div></div>

                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-6 flex md:flex-row flex-col lg:justify-center lg:space-x-4 lg:space-y-0 space-y-4">
          <span className="text-sm hover:text-gray-300 flex items-center">
            {language?.name}
          </span>
          <span className="text-sm hover:text-gray-300 flex items-center">
            {address}
          </span>
          <span className="text-sm hover:text-gray-300 flex items-center">© Copyright Navio CM All Rights Reserved.</span>

        </div>
      </div>
    </footer>
  )
}

export default Footer
