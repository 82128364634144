export const backgroundcolor={
    ivory:'#FFFFF0',
    marble:'#F2F8FC',
    pearl:'#FCFC57',
    lavender:'#F4F1F8',
    coldsteel:'#F8F7F4',
    layout:'#F7F9FC',
    light:'#f7f7f7'
  }
  
  export const evaCouleur = {
    color_basic_100: "#E8E8E8",
    color_basic_200: "#DCDCDC",
    color_basic_300: "#D3D3D3",
    color_basic_400: "#C8C8C8",
    color_basic_500: "#BEBEBE",
    color_basic_600: "#B0B0B0",
    color_basic_700: "#A8A8A8",
    color_basic_800: "#989898",
    color_basic_900: "#888888",
    color_primary_100: "#F4F4F4",
    color_primary_200: "#E9E9E9",
    color_primary_300: "#BEBEBE",
    color_primary_400: "#7D7D7D",
    color_primary_500: "#272727",
    color_primary_600: "#211C1C",
    color_primary_700: "#1C1315",
    color_primary_800: "#160C0F",
    color_primary_900: "#12070B",
    color_success_100: "#D0FED6",
    color_success_200: "#50C878",
    color_success_300: "#71BC78",
    color_success_400: "#228B22",
    color_success_500: "#17fd90",
    color_success_600: "green",
    color_success_700: "#00693E",
    color_success_800: "#006400",
    color_success_900: "#355E3B",
    color_success2_100: "#E0F7FA",
    color_success2_200: "#A5FEE0",
    color_success2_300: "#79FED9",
    color_success2_400: "#50C878",
    color_success2_500: "#17fd90",
    color_success2_600: "#0096c7",  // Light Blue
    color_success2_700: "#0077b6",  // Medium Blue
    color_success2_800: "#005e99",  // Darker Blue
    color_success2_900: "#004680",
    color_info_100: "#C9FCEE",
    color_info_200: "#95F9E6",
    color_info_300: "#5EEDDF",
    color_info_400: "#36DBD8",
    color_info_500: "#00B7C4",
    color_info_600: "green",
    color_info_700: "#006C8D",
    color_info_800: "#004D71",
    color_info_900: "#00385E",
    color_warning_100: "#FDF0CB",
    color_warning_200: "#FBDD97",
    color_warning_300: "#F4C263",
    color_warning_400: "#E9A53B",
    color_warning_500: "#DB7D02",
    color_warning_600: "#BC6301",
    color_warning_700: "#9D4C01",
    color_warning_800: "#7F3700",
    color_warning_900: "#692900",
    color_danger_100: "#FFD8DE",  
    color_danger_200: "#FFB2C5",
    color_danger_300: "#FF8BB3",
    color_danger_400: "#FF6FAF",
    color_danger_500: "#FF3FA8",
    color_danger_600: "#DB2E9E",
    color_danger_700: "#B71F91",
    color_danger_800: "#931480",  
    color_danger_900: "#7A0C74", 
    color_red_100: "#FFEBEE",
    color_red_200: "#FFCDD2",
    color_red_300: "#EF9A9A",
    color_red_400: "#E57373",
    color_red_500: "#EF5350",
    color_red_600: "#F44336",
    color_red_700: "#E53935",
    color_red_800: "#D32F2F",
    color_red_900: "#C62828",
  }
  
  export const couleur= {
    primary: '#272727',
    secondary: '#FDDA5A',
    old_tertiair : '#3366FF',
    tertiair:'#01bd64',
    navio_primary : '#01bd64',
    navio_secondary : '#fffa62',
    navio_tertiair: '#e6ffe6',
    navio_card_road_local: '#cadbc3',
    navio_card_road_highway: '#01bd64',
    navio_card_road: '#fbfffa',
    navio_card_water: '#01bd64',
    accent1: "#ff5722",
    accent2: "#2196f3",
    accent3: "#9c27b0",
    accent4: "#4caf50",
    accent5: "#ffc107",
    accent6: "#795548",
    accent7: "#f44336",
    navio_progress: evaCouleur.color_basic_400,
    navio_closed: evaCouleur.color_primary_900,
    navio_danger : evaCouleur.color_red_700
  }
  
  export const navioCouleur = {
  primary: {
    main: "#01bd64",
    lighter: "#64ffbf",
    darker: "#007c3c",
  },
  secondary: {
    main: "#fffa62",
    lighter: "#ffff9e",
    darker: "#ccc829",
  },
  accent: {
    main: "#ff7f50",
    lighter: "#ffa982",
    darker: "#cc5b36",
  },
  }
  