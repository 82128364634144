export function getBoundsCoordinates(from, to) {
    let southwestLat, northeastLat, southwestLng, northeastLng
    if (from.lat < to.lat) {
      southwestLat = from.lat
      northeastLat = to.lat
    } else {
      southwestLat = to.lat
      northeastLat = from.lat
    }
  
    if (from.lng < to.lng) {
      southwestLng = from.lng
      northeastLng = to.lng
    } else {
      southwestLng = to.lng
      northeastLng = from.lng
    }
  
    return { southwestLat, northeastLat, southwestLng, northeastLng }
  }

  export const languages = [
    // English and variants
    { code: 'en', name: 'English' },
    { code: 'en-US', name: 'English (United States)' },
    { code: 'en-GB', name: 'English (United Kingdom)' },
    { code: 'en-CA', name: 'English (Canada)' },
    { code: 'en-AU', name: 'English (Australia)' },
    { code: 'en-CM', name: 'English (Cameroon)' },
    
    // French and variants
    { code: 'fr', name: 'Français' },
    { code: 'fr-FR', name: 'Français (France)' },
    { code: 'fr-CA', name: 'Français (Canada)' },
    { code: 'fr-BE', name: 'Français (Belgium)' },
    { code: 'fr-CI', name: 'Français (Côte d\'Ivoire)' },
    { code: 'fr-CM', name: 'Français (Cameroun)' },
    
    // Arabic and variants
    { code: 'ar', name: 'العربية' },
    { code: 'ar-SA', name: 'العربية (السعودية)' },
    { code: 'ar-EG', name: 'العربية (مصر)' },
    { code: 'ar-DZ', name: 'العربية (الجزائر)' },
    { code: 'ar-CM', name: 'العربية (Cameroon)' },
    
    // Swahili and variants
    { code: 'sw', name: 'Kiswahili' },
    { code: 'sw-KE', name: 'Kiswahili (Kenya)' },
    { code: 'sw-TZ', name: 'Kiswahili (Tanzania)' },
    
    // Hausa and variants
    { code: 'ha', name: 'Hausa' },
    { code: 'ha-Latn', name: 'Hausa (Latin)' },
    { code: 'ha-CM', name: 'Hausa (Cameroon)' },
    
    // Amharic and variants
    { code: 'am', name: 'አማርኛ' },
    { code: 'am-ET', name: 'አማርኛ (ኢትዮጵያ)' },
    
    // Somali and variants
    { code: 'so', name: 'Somali' },
    { code: 'so-KE', name: 'Somali (Kenya)' },
    { code: 'so-SO', name: 'Somali (Somalia)' },
    
    // Afrikaans
    { code: 'af', name: 'Afrikaans' },
    { code: 'af-ZA', name: 'Afrikaans (South Africa)' },
    
    // Portuguese and variants
    { code: 'pt', name: 'Português' },
    { code: 'pt-PT', name: 'Português (Portugal)' },
    { code: 'pt-BR', name: 'Português (Brasil)' },
    { code: 'pt-CM', name: 'Português (Cameroon)' },
    
    // Zulu
    { code: 'zu', name: 'Zulu' },
    { code: 'zu-ZA', name: 'Zulu (South Africa)' },
    
    // Xhosa
    { code: 'xh', name: 'Xhosa' },
    { code: 'xh-ZA', name: 'Xhosa (South Africa)' },
    
    // Yoruba
    { code: 'yo', name: 'Yorùbá' },
    { code: 'yo-NG', name: 'Yorùbá (Nigeria)' },
    
    // Igbo
    { code: 'ig', name: 'Igbo' },
    { code: 'ig-NG', name: 'Igbo (Nigeria)' },
    
    // Lingala
    { code: 'ln', name: 'Lingala' },
    { code: 'ln-CD', name: 'Lingala (Congo - Kinshasa)' },
    
    // Kinyarwanda
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'rw-RW', name: 'Kinyarwanda (Rwanda)' },
    
    // Kirmanjki (Kurmanji)
    { code: 'kmr', name: 'Kurdî' },
    
    // Tigrinya
    { code: 'ti', name: 'ትግርኛ' },
    { code: 'ti-ER', name: 'ትግርኛ (Eritrea)' },
    { code: 'ti-ET', name: 'ትግርኛ (Ethiopia)' },
    
    // Sesotho
    { code: 'st', name: 'Sesotho' },
    { code: 'st-ZA', name: 'Sesotho (South Africa)' },
    
    // Chichewa
    { code: 'ny', name: 'Chichewa' },
    { code: 'ny-MW', name: 'Chichewa (Malawi)' },
    
    // Malagasy
    { code: 'mg', name: 'Malagasy' },
    { code: 'mg-MG', name: 'Malagasy (Madagascar)' },
    
    // Wolof
    { code: 'wo', name: 'Wolof' },
    { code: 'wo-SN', name: 'Wolof (Senegal)' },
    
    // Bemba
    { code: 'bem', name: 'Bemba' },
    { code: 'bem-ZM', name: 'Bemba (Zambia)' },
    
    // Tigré
    { code: 'tig', name: 'Tigré' },
    { code: 'tig-ER', name: 'Tigré (Eritrea)' },
    
    // Piti
    { code: 'pti', name: 'Piti' },
    { code: 'pti-CM', name: 'Piti (Cameroon)' },
    
    // Shubi
    { code: 'shb', name: 'Shubi' },
    { code: 'shb-CM', name: 'Shubi (Cameroon)' },
    
    // Maka
    { code: 'mak', name: 'Maka' },
    { code: 'mak-CM', name: 'Maka (Cameroon)' },
    
    // Moundang
    { code: 'mua', name: 'Moundang' },
    { code: 'mua-CM', name: 'Moundang (Cameroon)' },
    
    // Ngiemboon
    { code: 'ngg', name: 'Ngiemboon' },
    { code: 'ngg-CM', name: 'Ngiemboon (Cameroon)' },
    
    // Bassa
    { code: 'bss', name: 'Bassa' },
    { code: 'bss-CM', name: 'Bassa (Cameroon)' },
    
    // Duala
    { code: 'dua', name: 'Duala' },
    { code: 'dua-CM', name: 'Duala (Cameroon)' },
    
    // Ewondo
    { code: 'ewo', name: 'Ewondo' },
    { code: 'ewo-CM', name: 'Ewondo (Cameroon)' },
    
    // Fulfulde (Fula)
    { code: 'ff', name: 'Fulfulde' },
    { code: 'ff-CM', name: 'Fulfulde (Cameroon)' },
    
    // Beti
    { code: 'bnt', name: 'Beti' },
    { code: 'bnt-CM', name: 'Beti (Cameroon)' },
    
    // Gbiri-Nlki
    { code: 'gbl', name: 'Gbiri-Nlki' },
    { code: 'gbl-CM', name: 'Gbiri-Nlki (Cameroon)' },
    
    // Ngiemboon
    { code: 'ngg', name: 'Ngiemboon' },
    { code: 'ngg-CM', name: 'Ngiemboon (Cameroon)' },
    
    // Kirmanjki (Kurmanji)
    { code: 'kmr', name: 'Kurdî' },
    
    // Other languages with no specific Cameroonian variant
    { code: 'ln', name: 'Lingala' },
    { code: 'ln-CD', name: 'Lingala (Congo - Kinshasa)' },
    { code: 'kj', name: 'Kuanyama' },
    { code: 'kj-NA', name: 'Kuanyama (Namibia)' },
    { code: 'ts', name: 'Tswana' },
    { code: 'ts-ZA', name: 'Tswana (South Africa)' },
    { code: 'zu', name: 'Zulu' },
    { code: 'zu-ZA', name: 'Zulu (South Africa)' },
    
    // Other languages
    { code: 'ar', name: 'العربية' },
    { code: 'pt', name: 'Português' },
  ];
export const conditions = [
  {"id":"1","lang_id":"2","country":"Cameroun","title":"cookies_cameroun_en","url":"https://rxauto.be/policies/cookies_cameroun_en.pdf","type":"Cookies","lang":"en"},
  {"id":"2","lang_id":"1","country":"Cameroun","title":"cookies_cameroun_fr","url":"https://rxauto.be/policies/cookies_cameroun_fr.pdf","type":"Cookies","lang":"fr"},
  {"id":"3","lang_id":"2","country":"France","title":"cookies_france_en","url":"https://rxauto.be/policies/cookies_france_en.pdf","type":"Cookies","lang":"en"},
  {"id":"4","lang_id":"1","country":"France","title":"cookies_france_fr","url":"https://rxauto.be/policies/cookies_france_fr.pdf","type":"Cookies","lang":"fr"},
  {"id":"5","lang_id":"2","country":"France","title":"mentions_legales_france_en","url":"https://rxauto.be/policies/mentions_legales_france_en.pdf","type":"Legale","lang":"en"},
  {"id":"6","lang_id":"1","country":"France","title":"mentions_legales_france_fr","url":"https://rxauto.be/policies/mentions_legales_france_fr.pdf","type":"Legale","lang":"fr"},
  {"id":"7","lang_id":"2","country":"Cameroun","title":"politique_protection_vie_privee_cameroun_en","url":"https://rxauto.be/policies/politique_protection_vie_privee_cameroun_en.pdf","type":"Privacy","lang":"en"},
  {"id":"8","lang_id":"1","country":"Cameroun","title":"politique_protection_vie_privee_cameroun_fr","url":"https://rxauto.be/policies/politique_protection_vie_privee_cameroun_fr.pdf","type":"Privacy","lang":"fr"},
  {"id":"9","lang_id":"2","country":"France","title":"politique_protection_vie_privee_france_en","url":"https://rxauto.be/policies/politique_protection_vie_privee_france_en.pdf","type":"Privacy","lang":"en"},
  {"id":"10","lang_id":"1","country":"France","title":"politique_protection_vie_privee_france_fr","url":"https://rxauto.be/policies/politique_protection_vie_privee_france_fr.pdf","type":"Privacy","lang":"fr"}
  ]  