import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { couleur, evaCouleur, navioCouleur } from '../color'

const QrcodeSnanning = ({link}) => {
  
  return (
    <section
    className={`blok  bg-gray-100 flex items-center justify-center p-8 relative`}
  >
    <div className="flex lg:flex-row flex-col py-12 sm:flex-col lg:gap-x-16 gap-y-4">
        <img
          className="md:h-96 h-auto w-auto rounded shadow-lg"
          src={require('../assets/illustrationnavio/scan/scan.jpg')}
          alt=""
        />
        <div className="max-w-xl">
          <h2 className="text-5xl font-bold text-gray-900">
            Scan du code QR avant le début de la course
          </h2>
          <p className="mt-4">
          Avant de commencer leur course, les clients doivent scanner le code QR du chauffeur. Cette
          fonctionnalité permet de vérifier l’identité du conducteur et d'assurer la sécurité de la course.
           Une fois le code QR scanné, le client peut confirmer que les informations
           du chauffeur correspondent à celles de la réservation. 
           Cela garantit que le trajet se déroule avec le bon conducteur 
           et ajoute une couche de confiance et de sécurité au service.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href={link}
              target="_blank"
              className={`rounded-md bg-[${couleur.primary}] px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              Commencez une course en toute sécurité
            </a>
          </div>
        </div>
      </div></section>
  )
}

export default QrcodeSnanning