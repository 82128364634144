import React from "react";

const Spinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white" style={{zIndex:9999}}>
      <div role="status">
        <img
          className="h-8 w-auto loading-image"
          src={require("./assets/brand/logo_navio.png")}
          alt="Navio"
        />
      </div>
    </div>
  );
};

export default Spinner;
