import React, { useState } from 'react'

export default function Info() {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  return (
    <section
      className="blok relativ grid justify-items-stretch"
      style={{
        backgroundImage: `url(${require('../assets/img/infospeciaal.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="max-w-3xl px-8  py-16 md:justify-self-center text-white">
        <h2 className="text-3xl font-bold text-gray-200">
          Avec <strong>Navio</strong>, profitez de :
        </h2>

        <p className="mt-4">
          Réservations instantanées : Commandez votre trajet en quelques clics seulement, à tout
          moment et en tout lieu. Plus besoin d&apos;attendre, un véhicule est toujours disponible à
          proximité.
        </p>
        <p className="mt-2">
          Type de course : Que vous ayez besoin d&apos;une course rapide ou que vous souhaitiez
          planifier un trajet pour une autre date, Navio s&apos;adapte à vos besoins.{' '}
        </p>
        <p className="mt-2">
          Suivi en temps réel : Suivez votre véhicule en temps réel grâce à notre application
          intuitive et restez informé de l&apos;heure exacte de son arrivée. Options de trajet
          variées : Que vous voyagiez seul ou en groupe, choisissez parmi une gamme de véhicules
          adaptés à vos besoins
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-200">
          Fonctionnalités supplémentaires :
        </h3>
        <ul className="mt-4 list-disc list-inside text-gray-300">
          <li>
            <strong>Service clientèle 24/7 :</strong> Notre équipe de support est disponible à toute
            heure pour répondre à vos questions et résoudre vos problèmes.
          </li>
          <li>
            <strong>Paiement sécurisé :</strong> Payez facilement et en toute sécurité via notre
            application.
          </li>
        </ul>
      </div>
    </section>
  )
}
