import React, { useState } from 'react'
import { couleur, navioCouleur } from '../color'

export default function Driver({link}) {
  return (
    <section
      className={`blok bg-[${navioCouleur.primary.main}] md:bg-green-100 flex items-center justify-center p-8 relative`}
    >
      <div className="flex lg:flex-row flex-col py-12 sm:flex-col lg:gap-x-16 gap-y-4">
        <img
          className="md:h-96 h-auto w-auto rounded shadow-lg"
          src={require('../assets/img/driver.jpeg')}
          alt=""
        />
        <div className="max-w-xl">
          <h2 className="text-5xl font-bold text-gray-900">
            Roulez à votre convenance et générez des revenus facilement.
          </h2>
          <p className="mt-4">
            Générez de vrais revenus en roulant davantage :<br/> chaque nouvelle course est une
            opportunité de gain.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href={link}
              className={`rounded-md bg-[${couleur.primary}] md:bg-[${couleur.navio_primary}] px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            >
              Commencez à générer de l'argent
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
