import React, { useState } from 'react'
import Spinner from '../Spinner';

export default function Landingspage({images, link}) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true); // Update state when image is loaded
  };

  return (
    <>
       {!isLoaded && <Spinner/>}
    <section
      className="hidden lg:block xl:block md:block relative"
      style={{
        height: `${windowHeight}px`,
      }}
    >
      <img 
  onLoad={handleImageLoad} 
  className="absolute inset-0 w-full h-full object-cover" 
  src={images} 
  style={{ zIndex: 0 }} 
/>
      <div className="relative pt-40  lg:max-w-6xl max-w-5xl mx-auto flex">
        <div className="text-left max-w-xl ">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-white">
            Navio CM <br />
            Votre Compagnon <br />
            De Voyage Idéal
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 text-white">
            <p className="text-[#fffh33]">
              L&apos;Application de Transport Révolutionnaire pour des Déplacements Quotidiens
              Simplifiés.
            </p>
            Que ce soit pour le travail, une sortie en soirée ou un trajet vers l&apos;aéroport,
            Navio offre des solutions de transport rapides, fiables et sûres adaptées à tous vos
            besoins.
          </p>
          <div className="mt-10 flex items-center  gap-x-6">
            <a
              href={link}
              className="rounded-md bg-[#01bd64] px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
               Télecharger L'Application
            </a>
            {/*   <a href="#" className="text-sm font-semibold leading-6 text-white">
                Learn more <span aria-hidden="true">→</span>
              </a> */}
          </div>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </section>
    <section
     className="lg:hidden relative p-8"
     style={{
       backgroundColor:'black'
     }}
   >
     <div className="pt-20 mx-auto flex ">
       <div className="text-left max-w-xl">
         <h1 className="text-5xl font-bold tracking-tight text-gray-900 text-white">
           Navio CM <br />
           Votre Compagnon <br />
           De Voyage Idéal
         </h1>
         <p className="mt-6 text-lg leading-8 text-gray-600 text-white">
           <p className="text-[#fffh33]">
             L&apos;Application de Transport Révolutionnaire pour des Déplacements Quotidiens
             Simplifiés.
           </p>
           Que ce soit pour le travail, une sortie en soirée ou un trajet vers l&apos;aéroport,
           Navio offre des solutions de transport rapides, fiables et sûres adaptées à tous vos
           besoins.
         </p>
         <div className="mt-10 flex items-center  gap-x-6">
           <a
              href={link}
             className="rounded-md bg-[#01bd64] px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
           >
             Télecharger L'application
           </a>
           {/*   <a href="#" className="text-sm font-semibold leading-6 text-white">
               Learn more <span aria-hidden="true">→</span>
             </a> */}
         </div>
       </div>
     </div>
     <div
       className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
       aria-hidden="true"
     >
       <div
         className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
         style={{
           clipPath:
             'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
         }}
       />
     </div>
   </section>
   </>
  )
}