import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GOOGLE_API_KEY } from "../constans";
import { backgroundcolor, couleur } from "../color";
import GoogleMaps from "../GoogleMaps";
import { EllipsisVerticalIcon, MapPinIcon } from "@heroicons/react/24/outline";

const Maps = ({ link }) => {
  const [reservation, setReservation] = useState({
    origin: "",

    destination: "",
  });

  const [reservationKeep, setReservationKeep] = useState({});

  const onValueChangeAddress = async (event, label) => {
    const obj = event;
    const item = obj.value;
    //Check if the modal with ew prices have to be shown
    await geocodeByAddress(item.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        setReservation((prevField) => ({
          ...prevField,
          [label]: item?.description,
          [`${label}_lat_long`]: JSON.stringify({ lat, lng }),
        }))
      );
  };
  return (
    <section
      className={`blok bg-white flex items-center justify-center p-8 relative h-dvh`}
    >
      <div className="flex lg:flex-row flex-col py-12 sm:flex-col lg:gap-x-16 gap-y-4">
        <div className=" bg-white sm:bg-white p-8 md:rounded-lg flex flex-col lg:max-w-6xl max-w-5xl justify-center ">
          <h2 className="text-5xl font-bold text-gray-900 my-8">
            Où allez vous?
          </h2>
          <p className="mb-4">
            Réservez votre course dès maintenant et voyagez l'esprit tranquille
          </p>
          <div className="flex pr-2 gap-2">
            <div className="flex flex-col justify-center">
              <div className="p-1.5 rounded-md bg-gray-100">
                {" "}
                <MapPinIcon className="h-6 text-gray-700" />
              </div>

              <EllipsisVerticalIcon className="h-4" />
              <div className="p-1.5 rounded-lg bg-green-700">
                {" "}
                <MapPinIcon className="h-6 text-white" />
              </div>
            </div>
            <div className="flex-auto w-56">
              <div className="mb-3 ">
                <GooglePlacesAutocomplete
                  selectProps={{
                    escapeClearsValue: true,
                    placeholder: "Address du Départ",
                    isClearable: true,
                    onChange: (result) =>
                      onValueChangeAddress(result, "origin"),
                  }}
                  debounce={800}
                  minLengthAutocomplete={3}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["be"] },
                  }}
                  apiKey={GOOGLE_API_KEY}
                />
              </div>
              <div>
                <GooglePlacesAutocomplete
                  apiKey={GOOGLE_API_KEY}
                  debounce={800}
                  selectProps={{
                    escapeClearsValue: true,
                    //value: reservation.address_to,
                    placeholder: "Addresse d'Arrivé",
                    isClearable: true,
                    onChange: (result) =>
                      onValueChangeAddress(result, "destination"),
                  }}
                  minLengthAutocomplete={3}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["be"] },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-6">
            <a
              href={link}
              target="_blank"
              className="rounded-md bg-[#01bd64] px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Réservez une course des maintenant
            </a>
          </div>
        </div>
        <div className="flex md:flex-col justify-center p-8">
          <img
            className="md:h-96 rounded shadow-lg"
            src={require("../assets/illustrationnavio/voyage/voyage9.jpg")}
            alt=""
          />
        </div>
      </div>
      {/* <div>
        <GoogleMaps reservation={reservation} height=""/>
      </div>  */}
    </section>
  );
};

export default Maps;
