import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Landingspage from "./bloks/Landingspage";
import How from "./bloks/How";
import Info from "./bloks/Info";
import Footer from "./bloks/Footer";
import Client from "./bloks/Client";
import Driver from "./bloks/Driver";
import Countries from "./bloks/Countries";
import { Element, Link } from "react-scroll";
import Bienvenu from "./assets/img/background.png";
import Maps from "./bloks/Maps";
import { APIProvider } from "@vis.gl/react-google-maps";
import { GOOGLE_API_KEY } from "./constans";
import Fonctionalites from "./bloks/Fonctionalites";
import QrcodeSnanning from "./bloks/QrcodeSnanning";
import Contact from "./bloks/Contact";
import "./App.css";
import Qrcode from "./bloks/Qrcode";

const navigation = [
  { name: "Accueil", href: "#" },
  { name: "Qui sommes nous", href: "navio" },
  { name: "Info", href: "info" },
  { name: "Contactez-nous", href: "contact" },
];

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(navigator.platform === "MacIntel",userAgent , navigator)

    // Check if iPhone
    if (/iPhone/.test(userAgent)) {
      setDeviceType(
        "https://apps.apple.com/us/app/navio-cm-mobilit%C3%A9-facile/id6476240482"
      );
    }
    // Check if iPad (for older iOS versions)
    else if (
      /iPad/.test(userAgent) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      setDeviceType(
        "https://apps.apple.com/us/app/navio-cm-mobilit%C3%A9-facile/id6476240482"
      );
    }
    else if 
      (navigator.platform === "MacIntel")
     {
      setDeviceType(
        "https://apps.apple.com/us/app/navio-cm-mobilit%C3%A9-facile/id6476240482"
      );
    }
    // Check if Android tablet
    else if (/android/i.test(userAgent) && !/mobile/i.test(userAgent)) {
      setDeviceType(
        "https://play.google.com/store/apps/details?id=com.navio.rxautoapp"
      );
    }
    // Check if Android phone
    else if (/android/i.test(userAgent)) {
      setDeviceType(
        "https://play.google.com/store/apps/details?id=com.navio.rxautoapp"
      );
    }
    // Check if Windows PC, Mac, or other
    else {
      setDeviceType(
        "https://play.google.com/store/apps/details?id=com.navio.rxautoapp"
      );
    }
  }, []);

  if (Bienvenu)
    return (
      <div className="App bg-[#fff]">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src={require("./assets/brand/logo_navio.png")}
                  alt=""
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Link to={item.href} spy={true} smooth={true} duration={500}>
                  <div
                    key={item.name}
                    className="text-sm font-semibold leading-6 text-white cursor-pointer"
                  >
                    {item.name}
                  </div>
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-x-4">
              <a
                href={deviceType}
                className="text-sm font-semibold leading-6 text-white"
              >
                Se connecter
              </a>
              <a
                href={deviceType}
                className="text-sm font-semibold leading-6 text-white ring-1 rounded-lg ring-white px-2"
              >
                S'enregistrer
              </a>
            </div>
          </nav>
          <Dialog
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50 bg-white" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src={require("./assets/brand/logo_navio.png")}
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        <div
                          key={item.name}
                          href={item.href}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base cursor-pointer font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </div>
                      </Link>
                    ))}
                    <a href={deviceType}>
                      <div
                        key={"Se connecter"}
                        className="-mx-3 cursor-pointer block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {"Se connecter"}
                      </div>
                    </a>
                    <a href={deviceType}>
                      <div
                        key={"S'enregistrer"}
                        className="-mx-3 cursor-pointer block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {"S'enregistrer"}
                      </div>
                    </a>
                  </div>
                  <div className="py-6">
                    {/*  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a> */}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>
        <Landingspage link={deviceType} images={Bienvenu} />
        <Maps link={deviceType} />
        <Client link={deviceType} />
        <Fonctionalites />
        <Driver link={deviceType} />
        <Qrcode/>
        <QrcodeSnanning link={deviceType} />
        <Element name="info">
          <Info />
        </Element>
        <Element name="navio">
          <How />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
        <Countries link={deviceType} />
        <Footer link={deviceType} />
      </div>
    );
}

export default App;
