import React from 'react'
const files = [
    {
      title: 'Chauffeur profesionnel NAVIO',
      subtitle: 'Souhaitez-vous devenir chauffeur professionel Navio',
      source:require('../assets/illustrationnavio/voyage/voyage6.jpg')},
    {
        title: "Votre chauffeur, toujours à l'heure",
        subtitle: 'Notre promesse : un service ponctuel, fiable et adapté à vos besoins.',
        source:require('../assets/illustrationnavio/voyage/voyage5.jpg')},
      {
        title: 'Arrêtez un chauffeur en chemin',
        subtitle: "Besoin d'une course rapide ? Trouvez un chauffeur à proximité et montez à bord !",
        source:require('../assets/illustrationnavio/voyage/voyage7.jpg')},
      {
        title: 'Ne vous inquiétez pas, votre chauffeur est déjà en route!',
        subtitle: 'Profitez du trajet en toute sérénité, nous prenons soin de votre voyage.',
        source:require('../assets/illustrationnavio/voyage/voyage8.jpg')},
      {
          title: 'Course à multiples arrêts, adaptée à vos besoins',
          subtitle: "Planifiez plusieurs arrêts en toute simplicité et profitez d'un trajet flexible et pratique.",
          source:require('../assets/illustrationnavio/voyage/voyage4.jpg')},
        {
          title: 'Une course en toute sécurité',
          subtitle: "Votre sécurité, du départ à l'arrivée, est notre priorité absolue.",
          source:require('../assets/illustrationnavio/voyage/voyage.jpg')        },
  ]
const Fonctionalites = () => {
  return (
    <section class="relative md:h-dvh p-8">
        <h2 className="md:px-32 text-5xl font-bold text-gray-900 my-8">
        Notre vision</h2>
        <ul role="list" className="grid grid-cols-1 md:px-32 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
    {files.map((file) => (
      <li key={file.source} className="relative">
        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden shadow-md rounded-lg focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <div className="lg:h-60">
          <img alt="" src={file.source} className="md:object-cover md:h-60 md:w-96 py-4  pointer-events-none object-cover group-hover:opacity-75" />
          </div>
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details for {file.title}</span>
          </button>
        </div>
        <p className="pointer-events-none mt-2 block truncate md:text-sm font-medium text-gray-900">{file?.title}</p>
        <p className="pointer-events-none block md:text-sm font-medium text-gray-500">{file?.subtitle}</p>
      </li>
    ))}
  </ul>
  </section>
  )
}

export default Fonctionalites